/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs"
import "@reach/tabs/styles.css"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Container from "../components/container"
import Collection from "../components/artnum-collection"
import ArtNumItem from "../components/artnum-item"
import PersonneCollection from "../components/personne-collection"
import PersonneItem from "../components/personne-item"

const IndexPage = ({ data, ...props }) => (
  <Layout>
    <SEO title="Art Numérique" />
    <Helmet>
      <link rel="canonical" href={"https://lesfilmspelleas.com/artnum/"} />
    </Helmet>
    <Section.Title1 sx={{ mt: 5 }}>ART NUMÉRIQUE</Section.Title1>
    <Container isFull={false} isText={true}>
      <p
        sx={{
          margin: "0 auto",
          width: ["100%", "85%", "70%"],
          textAlign: "justify",
          my: 5,
        }}
      >
        La 3<sup>e</sup> scène, de l'Opéra national de Paris, est une scène
        numérique ouverte à de nouvelles créations et de nouveaux publics du
        monde entier. Sa direction artistique et sa production est assurée
        par les Films Pelléas.
      </p>
    </Container>
    <Tabs
      sx={{
        mt: 4,
        "> div": {
          backgroundColor: "rgba(255, 255, 255, 0.97)",
          width: "100%",
        },
      }}
      defaultIndex={props.location.hash === "#artistes" ? 1 : 0}
    >
      <TabList
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: ["20%", "30%", "40%"],
          position: "sticky",
          top: "61px",
          zIndex: 10,
          mb: 4,
        }}
      >
        <Tab
          sx={{
            display: "block",
            color: "gray",
            outline: "none",
            ":active": {
              backgroundColor: "inherit",
            },
          }}
        >
          ŒUVRES
        </Tab>
        <Tab
          sx={{
            display: "block",
            color: "gray",
            outline: "none",
            ":active": {
              backgroundColor: "inherit",
            },
          }}
        >
          ARTISTES
        </Tab>
        {/* <Tab
          sx={{
            display: "block",
            color: "gray",
            outline: "none",
            ":active": {
              backgroundColor: "inherit",
            },
          }}
        >
          MANIFESTO
        </Tab> */}
      </TabList>
      <TabPanels sx={{ py: 0 }}>
        <TabPanel>
          <Section isGrey={true} sx={{ py: 4 }}>
            <Container isFull={true} sx={{ px: 0 }}>
              <Collection>
                {data.artNum.nodes.map(artNum => (
                  <ArtNumItem isFluid={true} key={artNum.id} artNum={artNum} />
                ))}
              </Collection>
            </Container>
          </Section>
        </TabPanel>
        <TabPanel>
          <Section isGrey={true} sx={{ py: 4 }}>
            <Container isFull={true}>
              <PersonneCollection>
                {data.realisateurs.nodes.map(
                  personne =>
                    personne.Image_principale_optimized && (
                      <PersonneItem
                        isFluid={true}
                        key={personne.id}
                        personne={personne}
                      />
                    )
                )}
              </PersonneCollection>
            </Container>
          </Section>
        </TabPanel>
        {/* <TabPanel>
          <Section isGrey={true} sx={{ py: 5 }}>
            <Container isFull={false} isText={true}>
              <div
                sx={{
                  margin: "0 auto",
                  width: ["100%", "85%", "70%"],
                }}
              >
                <p sx={{ textAlign: "justify", mb: 3 }}>
                  Internet est une place publique, un lieu collectif de
                  rencontres, de prises de parole et de création. Après le
                  Palais Garnier en 1875 et l'Opéra Bastille en 1989, c’est sur
                  ces terres-là, celles du digital, que l'Opéra national de
                  Paris a décidé de bâtir sa 3e Scène. Dans ce nouvel espace,
                  l’Opéra national de Paris veut poursuivre le dialogue avec son
                  public et trouver de nouveaux interlocuteurs. Les spectateurs
                  de la 3e Scène habitent l’ensemble du globe, parlent toutes
                  les langues, aiment l’art sous toutes ses formes.
                </p>
                <p sx={{ textAlign: "justify", my: 3 }}>
                  A partir du 15 Septembre 2015, la 3e Scène s'ouvre en grand
                  aux plasticiens, cinéastes, compositeurs, photographes,
                  chorégraphes, écrivains, et les invite à venir créer des
                  œuvres originales liées à l’Opéra national de Paris. Ce lien
                  entre l’Opéra et les œuvres réalisées peut être franc, solide,
                  subliminal, étiré, allongé, voire distendu. Mais nous
                  souhaitons avant toute autre chose que les artistes s’emparent
                  de l’Opéra, puisent dans ses ressources, parcourent ses murs,
                  rencontrent ses talents, pour en faire découvrir les lieux,
                  les couleurs, l’histoire, les questions et les personnes à
                  travers la création.
                </p>
                <p sx={{ textAlign: "justify", mt: 3 }}>
                  Cette 3e Scène n’a pas d’égal ni de modèle. Elle se lance
                  librement et généreusement pour proposer un lieu où tradition,
                  création et nouvelle technologie sont, une fois mariés,
                  synonymes de modernité.
                </p>
              </div>
            </Container>
          </Section>
        </TabPanel> */}
      </TabPanels>
    </Tabs>
  </Layout>
)

export const query = graphql`
  query {
    artNum: allBubbleCreation(
      sort: { fields: Sortie, order: DESC }
      filter: {
        isArtNum: { eq: true }
        # Affiche_optimized: { ne: null }
        DELETED: { ne: true }
      }
      limit: 100
    ) {
      nodes {
        id
        Titre_FR
        slug
        CACHE_Realisateurs
        Affiche_optimized {
          childImageSharp {
            fluid(
              maxWidth: 400
              maxHeight: 255
              quality: 85
              cropFocus: CENTER
            ) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    realisateurs: allBubblePersonne(
      filter: {
        isArtiste: { eq: true }
        DELETED: { ne: true }
        # PUBLIE: {eq: true},
        # Featured: {eq: true}
      }
      sort: { fields: CACHE_Nom_tri }
    ) {
      nodes {
        Image_principale_optimized {
          childImageSharp {
            fluid(maxWidth: 170, maxHeight: 170, quality: 85) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
        CACHE_Nom_complet
        id
        slug
      }
    }
  }
`

export default IndexPage
